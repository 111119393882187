<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="类型" prop="type" >
        <a-select style="width: 100%" v-model="form.type" placeholder="请选择类型">
          <a-select-option v-for="(item, index) in this.customDict.CategoryTypeEnum" :value="item.type" :key="index">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
<!--      <a-form-model-item label="上级分类" prop="parentId" >
        <a-select placeholder="请选择上级分类" style="width: 100%" v-model="form.parentId" allowClear>
          <a-select-option v-for="category in categoryList" :value="category.id">
            {{category.name}}
          </a-select-option>
        </a-select>
      </a-form-model-item>-->
      <a-form-model-item label="分类名称" prop="name" >
        <a-input v-model="form.name" :maxLength="16" placeholder="请输入分类名称" />
      </a-form-model-item>
      <a-form-model-item label="分类封面" prop="img">
        <a-upload
          name="img"
          listType="picture-card"
          class="avatar-uploader"
          :multiple="false"
          :show-upload-list="false"
          :preview="handlePreview"
          :before-upload="beforeOssUpload"
          :customRequest="coverImgUpload">
          <img
            v-if="form.img"
            :src="form.img"
            alt="cover"
            style="height: 102px; width: 102px; border-radius: 50%"/>
          <div v-else>
            <a-icon :type="loading ? 'loading' : 'plus'"/>
            <div class="ant-upload-text">上传</div>
          </div>
        </a-upload>
      </a-form-model-item>
      <a-form-model-item label="排序" prop="sort" >
        <a-input-number v-model="form.sort" placeholder="请输入排序" />
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import {getCategory, addCategory, updateCategory, listCategory} from '@/api/res/category'
import {mapGetters} from 'vuex'
import {generateFilePath, uploadObject} from '@/api/tool/alioss'
import CustomDictTag from "@/components/DictCustomTag";
import {fileUpload} from "@/api/tool/common";

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    CustomDictTag,
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      categoryList: [],//分类
      loading: '',
      // 表单参数
      form: {
        id: null,

        parentId: null,

        level: null,
        img: null,

        name: null,

        type: null,

        sort: null,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        name: [
          { required: true, message: '分类名称不能为空', trigger: 'blur' }
        ],
        type: [
          { required: true, message: '20:商铺,,30:会议室,40:广告不能为空', trigger: 'change' }
        ],
      }
    }
  },
  filters: {
  },
  created () {
    this.getCategoryList()
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
      this.reset()
    },
    getCategoryList() {
      listCategory({level: 1}).then(response => {
        this.categoryList = response.data
      })
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        parentId: null,
        level: null,
        img:null,
        name: null,
        type: null,
        sort: null,
        createTime: null,
        remark: null,
      }
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getCategory({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    // oss 上传开始
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    // 上传之前校验
    beforeOssUpload(file) {
      // const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 20
      // if (!isJPG) {
      //     this.$message.error('上传头像图片只能是 JPG 格式!');
      // }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 20MB!')
      }
      return isLt2M
    },
    // 上传覆盖默认的上传行为
    coverImgUpload(file, index) {
      const _this = this
      // 获取文件对象
      const uploadFile = file.file
      // 文件夹目录
      const prefix = 'category'
      const url = _this.uploadAliYunOss(uploadFile.name, prefix)
      /*_this.$nextTick(() => {
        uploadObject(url, uploadFile).then(res => {
          _this.form.masterImg = res
          _this.$message.success('上传成功')
        })
      })*/
      const formData = new FormData()
      formData.append('file', uploadFile, url)
      _this.$nextTick(() => {
        fileUpload(formData).then(res => {
          _this.$set(_this.form, 'img', res.data.fullPath)
          _this.$message.success('上传成功')
        })
      })
    },
    /**
     * 文件上传到oss返回url地址
     * @param { file } 文件对象
     */
    uploadAliYunOss(fileName, prefix) {
      // 获取文件后缀
      const suffix = fileName.substring(fileName.lastIndexOf('.'))
      // 生成文件名称
      const filePath = generateFilePath(prefix, suffix)
      return filePath
    },
    // oss 上传结束

    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateCategory(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addCategory(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
